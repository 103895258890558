import React from "react";
import { IoPaw } from "react-icons/io5";
import styled from "styled-components";
import Layout from "../components/Layout";
import { queries } from "../shared/layout";

import { Link } from "gatsby";
import Warning from "../images/warning.svg";

const NotFoundPage = () => {
  return (
    <Layout>
      <Wrapper>
        <h3>Nie znaleziono strony...</h3>
        <img src={Warning} alt="" />
        <Link to="/">
          <IoPaw className="icon" />
          <span>Strona główna</span>
        </Link>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.section`
  width: 100%;
  height: 80vh;
  padding: 4rem;
  gap: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 60%;
  }

  a {
    height: 5rem;
    width: 15rem;
    margin-top: 5rem;
    font-size: 2rem;
    border-radius: 2px;
    font-weight: 500;
    border: none;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
    transition: all 0.2s ease-in-out;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      z-index: 5;
      position: relative;
    }

    .icon {
      color: ${({ theme }) => theme.colors.primaryDark};
      width: 5rem;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 4;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;
    }

    &:hover {
      clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
      transform: scale(1.1);

      .icon {
        visibility: visible;
        opacity: 1;
      }
    }

    ${queries.tablet} {
      flex-direction: row;
    }
  }

  ${queries.tablet} {
    gap: 2.5rem;

    img {
      height: 45%;
    }
  }
`;

export default NotFoundPage;
